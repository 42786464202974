
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Modal from '@/components/shared/Modal.vue';
import GenericTableLocations from '@/components/GenericTableLocations.vue';

const namespace: string = 'cart';

@Component<GenericTableLocationsModal>({
	components: {
		Modal,
		GenericTableLocations
	}
})

export default class GenericTableLocationsModal extends Vue {
	@Action('setSelectedTable', { namespace }) private setSelectedTable!: (payload: GenericTableLocation) => void;
	@Getter('getTableNum', { namespace }) private tableNum!: string | null;
	@Getter('isTakeOut', { namespace }) private isTakeOut!: boolean;
	@Getter('isGenericTableLocation', { namespace }) private isGenericTableLocation!: boolean;
	@Getter('getGenericTableLocations', { namespace: 'restaurant' }) private genericTableLocations!: GenericTableLocations | null;

	private genericTableLocationModalOpened: boolean = false;

	private created(): void {
		// Generic table location (user must choose their location)
		if(!this.isTakeOut && !this.tableNum && this.genericTableLocations && this.isGenericTableLocation) {
			this.genericTableLocationModalOpened = true;
			document.documentElement.classList.add('modal-open');
		}
	}

	/**
	 * Set the table chosen from the generic location selector
	 *
	 * @return {void}
	 */
	private setTable(payload: GenericTableLocation): void {
		this.hideGenericTableLocationModal();

		// Need a slight delay here for the situations where menu dropdown is open on load
		// Prevents the menu dropdown from closing when the user is selecting their table location
		// $nextTick() doesn't delay long enough
		setTimeout(() => {
			this.setSelectedTable(payload);
		}, 0);
	}

	/**
	 * Hides the orders paused modal and allow scrolling again
	 *
	 * @return {void}
	 */
	private hideGenericTableLocationModal(): void {
		this.genericTableLocationModalOpened = false;
		document.documentElement.classList.remove('modal-open');
	}
}
