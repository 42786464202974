
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { VueMasonryPlugin } from 'vue-masonry';
import { DateTime } from 'luxon';
import { changeFavicon } from '@/utils/styling';
import VueMeta from 'vue-meta';
import debounce from 'lodash/debounce';
import Navbar from '@/components/navigation/Navbar.vue';
import MembershipProgramDrawer from '@/components/navigation/MembershipProgramDrawer.vue';
import Modal from '@/components/shared/Modal.vue';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';
import GenericTableLocationsModal from '@/components/shared/GenericTableLocationsModal.vue';
import MenuGroupResults from '@/components/MenuGroupResults.vue';
import MenuResults from '@/components/MenuResults.vue';
import Filters from '@/components/filter/Filters.vue';
import BannerContainer from '@/components/shared/BannerContainer.vue';
import LoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import ProudPartnerOfApp8En from '../../assets/images/proud-partner-of-app8-en.svg?inline';
import ProudPartnerOfApp8Fr from '../../assets/images/proud-partner-of-app8-fr.svg?inline';

const namespace: string = 'cart';

Vue.use(VueMasonryPlugin);
Vue.use(VueMeta);

@Component<MenuPage>({
	components: {
		Navbar,
		Filters,
		BannerContainer,
		Modal,
		ErrorValidationInformation,
		GenericTableLocationsModal,
		MenuGroupResults,
		MenuResults,
		MembershipProgramDrawer,
		LoadingSpinner,
		ProudPartnerOfApp8En,
		ProudPartnerOfApp8Fr
	},
	metaInfo() {
		changeFavicon(this.restaurant.favicon);

		return {
			title: (this.$t('menu.meta.page_title', {restaurantName: this.restaurant.name})) as string,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'keyword', content: this.$t('menu.meta.keyword', {restaurantName: this.restaurant.name}) },
				{ name: 'description', vmid: 'description', content: this.$t('menu.meta.description') },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
				{ property: 'og:title', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:description', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:site_name', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'og:type', content: 'Website' },
				{ property: 'twitter:title', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) },
				{ property: 'twitter:description', content: this.$t('menu.meta.title', {restaurantName: this.restaurant.name}) }
			]
		};
	}
})
export default class MenuPage extends Vue {
	@Prop({ type: Boolean, required: true, default: '' }) private dataFetched!: boolean;
	@Prop({ type: Boolean, required: true, default: '' }) private cssLoaded!: boolean;

	// Cart
	@Action('updateValidationError', { namespace }) private updateValidationError!: (error: object) => void;
	@Action('setSelectedTable', { namespace }) private setSelectedTable!: (payload: GenericTableLocation) => void;
	@Action('setMembershipBanner', { namespace }) private setMembershipBanner!: (show: boolean) => void;
	@Getter('getMenuList', { namespace }) private menuList!: string[] | null;
	@Getter('getMemberId', { namespace }) private memberId!: string | null;
	@Getter('getMemberProvider', { namespace }) private memberProvider!: string | null;
	@Getter('showMembershipBanner', { namespace }) private showMembershipBanner!: boolean;
	@Getter('getContactName', { namespace }) private contactName!: string;
	@Getter('isTakeOut', { namespace }) private isTakeout!: boolean;
	@Getter('isGenericCatering', { namespace }) private isGenericCatering!: boolean;

	// Suites
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Getter('getUserEventSuite', { namespace: 'suites' }) private userEventSuite!: UserEvent;

	// Search
	@Getter('getSearchInput', { namespace: 'search' }) private searchText!: string;

	// Restaurant
	@Action('setMemberPrices', { namespace: 'restaurant' }) private setMemberPrices!: (menus: Menu[]) => Promise<Menu[]>;
	@Action('setFilteredMenus', { namespace: 'restaurant' }) private setFilteredMenus!: (menus: Menu[]) => Promise<Menu[]>;
	@Getter('getRestaurant', { namespace: 'restaurant' }) private restaurant!: Restaurant;
	@Getter('getRestaurantMembershipCustomizationLoadingMessage', { namespace: 'restaurant' }) private membershipCustomizationLoadingMessage!: string;
	@Getter('getRestaurantMembershipCustomization', { namespace: 'restaurant' }) private membershipCustomization!: MembershipCustomization;
	@Getter('getInitialMenuGroups', { namespace: 'restaurant' }) private initialMenuGroups!: MenuGroup[];
	@Getter('getInitialMenus', { namespace: 'restaurant' }) private initialMenus!: Menu[];
	@Getter('getFilteredMenus', { namespace: 'restaurant' }) private filteredMenus!: Menu[];
	@Getter('getMemberMenus', { namespace: 'restaurant' }) private memberMenus!: Menu[];
	@Getter('getSupportEmail', { namespace: 'restaurant' }) private supportEmail!: string | null;
	@Getter('isMembershipProgramOn', { namespace: 'restaurant' }) private isMembershipProgramOn!: string;
	@Getter('isMarketplaceHub', { namespace: 'restaurant' }) private isMarketplaceHub!: boolean;

	// Auth
	@Action('removeWelcomeBanner', { namespace: 'auth' }) private removeWelcomeBanner!: () => void;
	@Getter('getFullName', { namespace: 'auth' }) private fullName!: string;
	@Getter('showWelcomeBanner', { namespace: 'auth' }) private showWelcomeBanner!: boolean;
	@Getter('showLoginAsBanner', { namespace: 'auth' }) private showLoginAsBanner!: boolean;
	@Getter('isAuthenticated', { namespace: 'auth' }) private isAuthenticated!: boolean;
	@Getter('isSuiteOperatorLogin', { namespace: 'auth' }) private isSuiteOperatorLogin!: boolean;

	@Ref('nav') readonly navRef!: InstanceType<typeof Navbar>;
	@Ref('banners') readonly bannersRef!: InstanceType<typeof BannerContainer>;
	@Watch('busy')
	onBusyUpdate() {
		// Check nav height
		this.$nextTick(() => {
			this.checkNavHeight();
		});
	}
	private busy: boolean = true;
	private filterOpen: Boolean = false;
	private orderPaused: boolean = false;
	private orderPausedModalOpened: boolean = false;
	private reorderChangeModalOpened: boolean = false;
	private membershipAppliedSuccesfully: boolean = false;
	private menus: Menu[] = [];
	private elementSelected: string = '';
	private currentElementIndex: number = 0;
	private scrollY: number = 0;
	private navHeight: number = 0;
	private maintenanceOnGoing: boolean = false;

	private get hasMembershipInfo(): boolean {
		return !!(this.memberId && this.isMembershipProgramOn && this.membershipCustomization && this.membershipCustomization.banner_message);
	}

	private get menuBanners(): BannerItem[] {
		return [
			{
				name: 'orders-paused',
				type: 'warning',
				show: this.restaurant.orders_paused,
				message: this.$t('menu.error_orders_paused_banner')
			},
			{
				name: 'membership',
				type: 'info',
				show: !!(this.hasMembershipInfo && this.showMembershipBanner),
				message: `${this.$t('membership_drawer.spinner.welcome_back_membership')}${this.contactName ? ` ${this.contactName}` : ''}, ${this.membershipCustomization && this.membershipCustomization.banner_message}`,
				closeOnSwipe: true,
				action: {
					text : this.$t('membership_drawer.btn_dismiss'), 
					onClick : () =>  this.setMembershipBanner(false)
				}
			},
			{
				name: 'login-as',
				type: 'info',
				show: !!(this.showLoginAsBanner && this.fullName),
				message: this.showLoginAsBanner ? this.$t('menu.logged_in_as_banner') : this.$t('menu.welcome_back_banner'),
				action: {
					text : this.fullName,
					onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
				}
			},
			{
				name: 'holiday',
				type: 'info',
				show: this.isHoliday && !this.isPreOrdering,
				message: (this.isTakeout || this.isGenericCatering) ? this.$t('menu.location_closed_banner_takeout') : this.$t('menu.location_closed_banner_dinein'),
			},
			{
				name: 'welcome',
				type: 'info',
				show: !!(this.showWelcomeBanner && this.fullName && !this.showMembershipBanner && !this.hasMembershipInfo),
				duration: 10000,
				message: this.showWelcomeBanner ? this.$t('menu.welcome_back_banner') : '',
				action: {
					text : this.fullName,
					onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
				}
			}
		];
	}

	private get loadingMessasge(): string {
		if (this.isMembershipProgramOn && this.memberId) {
			return `${this.$t('membership_drawer.spinner.welcome_back_membership')} ${this.contactName ? this.contactName : ''}, ${this.membershipCustomizationLoadingMessage}`;
		}
		else if (this.contactName) {
			return this.$t('membership_drawer.spinner.welcome_back_name', { contactName: this.contactName })
		}
		else {
			return this.$t('membership_drawer.spinner.welcome_back_general')
		}
	}

	/**
	 * Check if today is a holiday
	 * Will need to be updated to check for holiday hours in the future
	 *
	 * @return {boolean}
	 */
	private get isHoliday(): boolean {
		const today = DateTime.now().setZone(this.restaurant.zone || 'America/Toronto');
		const holidays = this.restaurant.holiday_hours;
		if (holidays && holidays.length) {
			return holidays.some((holiday: HolidayHours) => {
				return DateTime.fromISO(holiday.date).hasSame(today, 'day');
			});
		}
		return false;
	}

	/**
	 * Fetch the menus and filter them by types depending on query params.
	 * We also check if orders are paused, selected menu, etc. We do not trigger
	 * this if the menus are already fetched.
	 *
	 * @return {Promise<void>}
	 */
	private async created(): Promise<void> {
		if(this.maintenanceOnGoing) {
			this.updateValidationError({
				message: this.$t('maintenance_window')
			});
		}

		// TEMPORARY HARDCODED FOR BOWLERO.
		if(this.restaurant?.orders_paused && this.restaurant?.group === 'bowlero') {
			this.$router.push({ path: '/bowlero-coming-soon' }).catch(() => {});
		}

		// Show the modal warning of changes to the items when reordering from a past receipt
		if(this.$route.query.openCart === 'true' && this.$route.query.reorderWarning === 'true') {
			this.updateValidationError({
				message: this.$t('menu.reorder_change_warning_modal_text')
			});
			document.documentElement.classList.add('modal-open');
			this.reorderChangeModalOpened = true;
			let query = { ...this.$route.query };
			delete query.reorderWarning;
			this.$router.replace({ query }).catch(() => {});
		}

		// First load
		if(!this.filteredMenus || !this.filteredMenus.length) {
			try {
				// Get local menus and save the inital menus globally
				this.menus = this.initialMenus;
				// Display correct menus (takeout menus if takeout/generic catering, dinein menus if dinein)
				// and hide/show member menus. Also check if there is a menuList passed in the query params
				await this.findRespectiveMenus();

				// If no menus of a certain type, show an error modal.
				if(!this.menus.length && !this.restaurant?.orders_paused) {
					this.updateValidationError({
						message: this.$t(!this.initialMenus.length && !this.memberMenus.length ? 'menu.error_no_menus' : 'menu.error_no_menus_with_membership')
					});
					document.documentElement.classList.add('modal-open');
					this.orderPaused = true;
					this.orderPausedModalOpened = true;
				}
				setTimeout(() => {
					this.busy = false;
				}, 3000);
			} catch (err) {
				throw err;
			}
		}

		// Subsequent load
		else {
			// Show member menus with discounted prices and special menus
			if(this.memberMenus && this.memberMenus.length) {
				this.menus = this.memberMenus;
				this.setSelectedElement(true);
			}

			// Show filtered menus and make sure to filter out any member only menus
			else {
				this.menus = this.filteredMenus.filter((menu: Menu) => {
					return !menu.member_only;
				});
				this.setSelectedElement(false);
			}
			setTimeout(() => {
				this.busy = false;
			}, 100);
		}

		// Can't pre-order as a guest
		if((this.isSuiteOperatorLogin || this.isPreOrdering) && !this.isAuthenticated)  {
			this.$router.push({ path: '/login', query: this.$route.query }).catch(() => {});
		}

		// If game day ordering, tab limit must be set
		if(this.isEventDayOrdering && this.userEventSuite && !this.userEventSuite?.tab_limit)  {
			this.$router.push({ path: `${this.restaurant.slug}/suite-settings`, query: this.$route.query }).catch(() => {});
		}
	}

	/**
	 * Remove toasts when leaving page and remove resize event listener
	 *
	 * @return {void}
	 */
	private beforeDestroy(): void {
		this.removeWelcomeBanner();
		this.$toasted.clear();
		window.removeEventListener('resize', () => {})
	}

	/**
	 * Display menus depending on the type passed in the URL. After those menus
	 * are parsed we also show/hide the member menus and apply the discount if the
	 * user is a member.
	 *
	 * @param {Restaurant} restaurant
	 * @return {Promise<void>}
	 */
	private async findRespectiveMenus(): Promise<void> {
		// Show only takeout menus (generic catering acts as takeout as well)
		if(this.$route.query.takeout || this.$route.query.catering) {
			this.menus = this.menus.filter((menu: Menu) => {
				return menu.on_demand || menu.scheduled;
			});
		}

		// Show only dine-in menus
		else if(this.$route.query.tableNum) {
			this.menus = this.menus.filter((menu: Menu) => {
				return menu.dine_in;
			});
		}

		// If the orders are paused
		if(this.restaurant.orders_paused && this.restaurant.group !== 'bowlero') {
			this.updateValidationError({
				message: this.$t('menu.error_orders_paused')
			});
			document.documentElement.classList.add('modal-open');
			this.orderPaused = true;
			this.orderPausedModalOpened = true;
		}

		// Apply menu list if any
		this.setQueryParamsMenuList();

		// Final fitered menus by all settings (order type, member, etc)
		this.setFilteredMenus(this.menus);

		// Apply membership discount and display member menus if user is member
		await this.checkAndApplyMembership(true);
	}

	/**
	 * Add resize event listener
	 *
	 * @return {void}
	 */
	private mounted(): void {
		window.addEventListener('resize',
			debounce(() => {
				this.checkNavHeight()
			}, 100)
		);

		// Hide the membership drawer when coming back to the page if the user
		// already enrolled
		if(this.isMembershipProgramOn && this.memberId) {
			this.membershipAppliedSuccesfully = true;
		}
	}

	/**
	 * Check height of the navbar and update if there is a change
	 *
	 * @return {void}
	 */
	private checkNavHeight(): void {
		const nav = this.navRef?.$el;
		const banners = this.bannersRef?.$el;
		if (nav && banners && nav.clientHeight + banners.clientHeight !== this.navHeight ) {
			this.navHeight = nav.clientHeight + banners.clientHeight;
		}
	}

	/**
	 * Check and apply membership if the user is a member. This function runs on
	 * page load because from the cookies we save we can remember that the user
	 * was in fact a member and won't ask them to enter their phone number again.
	 * This function also runs when a new member does sign up with their phone number.
	 *
	 * @param {boolean} onLoad
	 * @return {void}
	 */
	private async checkAndApplyMembership(onLoad: boolean): Promise<void> {
		// If we're on the first page load, the spinner is already spinning
		if(!onLoad) {
			this.busy = true;
		}

		// If the user is a part of the membership program we get all menus
		if(this.isMembershipProgramOn && this.memberId) {
			this.menus = this.filteredMenus;

			// TEMPORARY HARDCODED FOR BOWLERO AND BYPASS/COMPASS. Needs the prices calculated since the discounts are directly on the items
			if(this.memberProvider === 'ELB') {
				this.menus = await this.setMemberPrices(this.menus);
			}

			// Set selected menu depending on hash or if the special menu is there
			this.setSelectedElement(true);
			this.membershipAppliedSuccesfully = true;
			this.setMembershipBanner(true);
		}

		// If the user is not a member of the program, we don't show memberOnly menus
		else {
			this.menus = this.filteredMenus.filter((menu: Menu) => {
				return !menu.member_only;
			});

			// Set selected menu depending on hash
			this.setSelectedElement(false);
		}

		if(!onLoad) {
			setTimeout(() => {
				this.busy = false;
			}, 4000);
		}
	}

	/**
	 * Set the correct menu / menu group passed in the URL
	 *
	 * @param {boolean} member
	 * @return {void}
	 */
	private setSelectedElement(member: boolean): void {
		// Menu groups
		if(this.isMarketplaceHub) {
			if(window.location.hash) {
				this.handleQueryHash();
			}
			else {
				this.elementSelected = this.initialMenuGroups[0] ? this.initialMenuGroups[0].name : '';
			}
		}

		// Menus
		else {
			// If the user is a member, we do not care about the hash and simply
			// want to get the user on the special menu
			if(member) {
				const specialMenuIndex = this.menus.findIndex((menu: Menu) => menu.member_only);
				const specialMenu = this.menus[specialMenuIndex];
				if(specialMenu) {
					this.currentElementIndex = specialMenuIndex;
					this.elementSelected = specialMenu.name;
					this.orderPaused = false;
					this.orderPausedModalOpened = false;
				}
			}

			// Check the hash is there is a menu selected
			else if(window.location.hash) {
				this.handleQueryHash();
			}

			// Default to first menu
			else {
				this.elementSelected = this.menus[0] ? this.menus[0].name : '';
			}
		}
	}

	/**
	 * Handle the hash query here. Hack for NFC tags that includes a hash. Because of the way the app works,
	 * if the URL on the NFC contains a hash, it won't be recognized because the query
	 * params are passed after. So here we reverse them if this happens, that way we
	 * can handle the hash to find the correct menu.
	 *
	 * @return {void}
	 */
	private handleQueryHash(): void {
		let slug = window.location.hash.split('#')[1];
		let queryIndex = slug.indexOf('?');
		if (queryIndex !== -1) {
			let query = slug.substring(queryIndex);
			slug = slug.substring(0, queryIndex);
			location.href = window.location.href.split(/[?#]/)[0].replace(/\/$/, '') + query + '#' + slug;
		}

		this.setSelectedMenu(slug);
	}

	/**
	 * Set the selected menu from the URL hash, if the slug is null or the slug
	 * does not exists at all, we default to the first menu.
	 *
	 * @param {string} slug
	 * @return {void}
	 */
	private setSelectedMenu(slug: string | null): void {
		// Set the selected menu
		if (slug && this.initialMenuGroups) {
			if(this.isMarketplaceHub) {
				if(this.initialMenuGroups) {
					const menuGroupIdx = this.initialMenuGroups.findIndex((m: MenuGroup) => m.slug === slug);
					const menuGroup = this.initialMenuGroups.find((m: MenuGroup) => m.slug === slug);
					if (menuGroup) {
						this.currentElementIndex = menuGroupIdx;
						this.elementSelected = menuGroup.name;
					}
				}
			}
			else {
				if(this.menus) {
					const menuIdx = this.menus.findIndex((m: Menu) => m.slug === slug);
					const menu = this.menus.find((m: Menu) => m.slug === slug);
					if (menu) {
						this.currentElementIndex = menuIdx;
						this.elementSelected = menu.name;
					}
				}
			}
		}
	}

	/**
	 * Recalculate the height of the navbar when a banner is removed
	 * Need the timeout to wait for the exit animation to finish
	 *
	 * @return {void}
	 */
	private handleBannerRemoved(): void {
		setTimeout(() => {
			this.checkNavHeight();
		}, 175);
	}

	/**
	 * Set menu list from the query params if any is passed. We also concat the member menus
	 * since those ones take priority over anything.
	 *
	 * NOTE: This kinda works fine with the menu groups, but it's not perfect, since the other
	 * menu groups would still show up anyway. Leaving this as is for now, but if the menuList
	 * query param is ever planned to be used with a marketplace location we'll have to keep
	 * this in mind.
	 *
	 * @return {void}
	 */
	private setQueryParamsMenuList(): void {
		if(this.menuList && this.menuList.length) {
			let menuListToShow: Menu[] = [];
			this.menuList.forEach((menuListed: string) => {
				const menuFound: Menu | undefined = this.menus.find((menu: Menu) => !menu.member_only && menu.slug === menuListed);
				if(menuFound) {
					menuListToShow.push(menuFound);
				}
			});

			// We make sure that the menus passed in the list were correct menus, if they were, we override the menus
			// with those ones
			if(menuListToShow && menuListToShow.length) {
				// We want to keep the member menus since those will override the menuList
				const memberMenus: Menu[] = this.menus.filter((menu: Menu) => {
					return menu.member_only;
				});
				// Concat any member menus
				this.menus = menuListToShow.concat(memberMenus);
			}
		}
	}

	/**
	 * Open filter panel as a modal.
	 *
	 * @return {void}
	 */
	private openFilterSelector(): void {
		this.filterOpen = true;
		scrollY = window.pageYOffset;
		document.documentElement.classList.add('modal-open');
		document.body.classList.add('modal-open');
		document.getElementById('app')!.style.top = `-${scrollY.toString()}px`;
	}

	/**
	 * Close filter panel.
	 *
	 * @return {void}
	 */
	private closeFilterSelector(): void {
		this.filterOpen = false;
		document.documentElement.classList.remove('modal-open');
		document.body.classList.remove('modal-open');
		document.getElementById('app')!.style.top = '';
		window.scrollTo({ top: scrollY });
	}

	/**
	 * Change view to the menu / menu group selected
	 *
	 * @param {number} idx
	 * @param {string} elementName
	 * @return {void}
	 */
	private selectElement(idx: number, elementName: string): void {
		if (!this.searchText){
			this.currentElementIndex = idx;
			this.elementSelected = elementName;
			window.scroll(0, 0);
		}
	}

	/**
	 * Hides the orders paused modal and allow scrolling again
	 *
	 * @return {void}
	 */
	private hideOrderPausedModal(): void {
		this.orderPausedModalOpened = false;
		document.documentElement.classList.remove('modal-open');
	}

	/**
	 * Hide the warning on reorder modal and allow scrolling again
	 *
	 * @return {void}
	 */
	private hideReorderModal(): void {
		this.reorderChangeModalOpened = false;
		document.documentElement.classList.remove('modal-open');
	}
}
