
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Validate } from '@/types';
import Cookies from 'js-cookie';
import MapPinIcon from 'vue-feather-icons/icons/MapPinIcon';
import '@/validation-rules';

const DEFAULT_GENERIC_TABLE_LOCATION = {
	location: '',
	area: '',
	tableNum: ''
};
const namespace: string = 'cart';

@Component<GenericTableLocationsComponent>({
	components: {
		MapPinIcon,
		ValidationObserver,
		ValidationProvider
	}
})

export default class GenericTableLocationsComponent extends Vue {
	@Prop({ type: Object, required: true, default: () => {} }) private genericTableLocations!: GenericTableLocations;
	@Action('setLegal', { namespace }) private setLegal!: (value: boolean) => void;
	@Getter('getErrorValidationInfo', { namespace }) private errorValidation!: ErrorValidation;
	@Getter('getRestaurantName', { namespace: 'restaurant' }) private restaurantName!: string;
	@Getter('getRestaurantGroup', { namespace: 'restaurant' }) private group!: string;
	private genericTableLocation: GenericTableLocation = DEFAULT_GENERIC_TABLE_LOCATION;
	private locations: CustomGenericTableLocation[] = [];
	private areas: string[] = [];

	$refs!: {observer: Validate}

	/**
	* Set locations on load
	*
	* @return {void}
	*/
	private created(): void {
		this.locations = this.genericTableLocations.custom;

		// If you select a table location at restaurant, then change to a different restaurant, the location from the previous restaurant will be filled
		// So we need to clear the table location
		if (this.genericTableLocation.location) {
			this.genericTableLocation.location = '';
			this.genericTableLocation.area = '';
			this.genericTableLocation.tableNum = '';
		}
	}

	/**
	 * Update the location chosen, grab the areas and split them into descriptive
	 * value and their table number.
	 *
	 * @param {CustomGenericTableLocation} tempGenericTableLocation
	 * @return {void}
	 */
	private updateLocation(tempGenericTableLocation: CustomGenericTableLocation): void {
		this.genericTableLocation.location = tempGenericTableLocation.location;

		// Clear out selected values
		this.areas = [];
		this.genericTableLocation.area = '';
		this.genericTableLocation.tableNum = '';

		// Create an object with $1:$2
		tempGenericTableLocation.areas.split(',').reduce((object: any, value: string) => {
			let strParts = value.split(":");
			if(strParts[0] && strParts[1]) {
				object = { area: strParts[0], tableNum: strParts[1].trim()};
				this.areas.push(object);
			}
			return object;
		}, {});
	}

	/**
	 * Update the area selected (area and tableNum)
	 *
	 * @param {GenericTableLocationArea} tempGenericTableLocationArea
	 * @return {void}
	 */
	private updateArea(tempGenericTableLocationArea: GenericTableLocationArea): void {
		this.genericTableLocation.area = tempGenericTableLocationArea.area;
		this.genericTableLocation.tableNum = tempGenericTableLocationArea.tableNum;
	}

	/**
	 * Update the table number on confirm
	 *
	 * @return {Promise<void>}
	 */
	private async updateTableNum(): Promise<void> {
		let isValid = await this.$refs.observer.validate();
		if(isValid) {
			this.$emit('setTable', this.genericTableLocation);
		}
	}

	/**
	 * Remove geolocation cookie and redirect to location search
	 *
	 * @return {void}
	 */
	private redirectToLocationSearch(): void {
		if (Cookies.get('currentLocation')) {
			Cookies.remove('currentLocation');
		}
		if (Cookies.get('previousLocation')) {
			Cookies.remove('previousLocation');
		}
		this.$router.push({ path: '/bowlero-location-search' }).catch(() => {});
	}
}
