
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
import CartItemList from './CartItemList.vue';
import CartPricing from './CartPricing.vue';
import CartSuiteTab from './CartSuiteTab.vue';
import BannerContent from '@/components/shared/BannerContent.vue';
import Modal from '@/components/shared/Modal.vue';
import ErrorValidationInformation from '@/components/shared/ErrorValidationInformation.vue';

const namespace: string = 'cart';

@Component<CartViewer>({
	components: {
		ArrowLeftIcon,
		BannerContent,
		CartPricing,
		CartItemList,
		CartSuiteTab,
		Modal,
		ErrorValidationInformation
	}
})
export default class CartViewer extends Vue {
	@Prop({ type: Boolean, required: true, default: false }) private displayChangePreOrderButton!: boolean;
	@Action('validateCart', { namespace }) private validateCart!: () => void;
	@Getter('getItems', { namespace }) private items!: MenuItem;
	@Getter('getSubtotal', { namespace }) private subtotal!: string;
	@Getter('getPromoDiscount', { namespace }) private promoDiscount!: string;
	@Getter('getMemberDiscount', { namespace }) private memberDiscount!: string;
	@Getter('getServiceCharge', { namespace }) private serviceCharge!: string | null;
	@Getter('getDeliveryCharge', { namespace }) private deliveryCharge!: string | null;
	@Getter('getTaxes', { namespace }) private taxes!: string;
	@Getter('getVoucherAmount', { namespace }) private voucherAmount!: string;
	@Getter('getTotal', { namespace }) private total!: string;
	@Getter('getTip', { namespace }) private tip!: string;
	@Getter('getTotalWithTip', { namespace }) private totalWithTip!: string;
	@Getter('isEventDayOrdering', { namespace: 'suites' }) private isEventDayOrdering!: boolean;
	@Getter('getUserEventSuite', { namespace: 'suites' }) private userEventSuite!: EventSuite | null;
	@Getter('isPreOrdering', { namespace: 'suites' }) private isPreOrdering!: boolean
	@Getter('isLoginAs', { namespace: 'auth' }) private isLoginAs!: boolean;
	@Getter('getFullName', { namespace: 'auth' }) private fullName!: string;
	private cartErrorModalOpened: boolean = false;

	/**
	 * Logged in as banner properties
	 *
	 * @return {BannerItem}
	 */
	private get loggedInAsBanner(): BannerItem {
		return {
			name: 'login-as',
			type: 'info',
			show: this.isLoginAs,
			message: this.$t('menu.logged_in_as_banner'),
			action: {
				text : this.fullName,
				onClick : () => this.$router.push({ path: '/profile', query: this.$route.query }).catch(() => {})
			}
		}
	}

	/**
	 * Go to the checkout view
	 *
	 * @return {void}
	 */
	private showCheckout(): void {
		this.$emit('checkout');
	}

	/**
	 * Send event to the parent
	 *
	 * @return {void}
	 */
	private closeModal(): void {
		this.$emit('close');
	}

	/**
	 * Validate the cart with the API to check many caveats
	 * (IE: schedule, different menus availability, sold_out, etc)
	 *
	 * @return {Promise<void>}
	 */
	private async validate(): Promise<void> {
		try {
			await this.validateCart();
			this.showCheckout();
		} catch (err) {
			this.cartErrorModalOpened = true;
		} finally {
			if (!this.cartErrorModalOpened) {
				this.closeModal();
			}
		}
	}

	/**
	 * Close the validation error modal
	 *
	 * @return {void}
	 */
	private closeCartErrorModal(): void {
		this.cartErrorModalOpened = false;
	}

}
